var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-left":"1.5%"}},[_c('v-row',{staticStyle:{"padding-top":"20px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pl-2 pl-md-10 pl-xl-10 pl-lg-10",attrs:{"cols":"11"}},[_c('span',{staticClass:"my-profile"},[_vm._v("My Profile")])])],1),_c('v-row',[_c('v-col',{staticClass:"pl-md-10 pl-xl-10 pl-lg-10 pr-12 pr-md-5 ml-3 ",attrs:{"cols":"12"}},[_c('v-divider',{staticStyle:{"border-color":"#206297","border-width":"2px"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-md-10 pl-xl-10 pl-lg-10 ml-3",staticStyle:{"padding-bottom":"120px"},attrs:{"cols":"8","sm":"8","md":"8","lg":"3","xl":"3"}},[_c('div',[_c('v-overlay',{attrs:{"value":_vm.$store.state.overlay.show,"absolute":false}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('div',{staticClass:"login-form"},[_c('validation-observer',{ref:"observer",on:{"change":function($event){return _vm.changeColorByObserver()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updateProfile(_vm.$data)}}},[_c('label',{staticStyle:{"padding-bottom":"5px"},attrs:{"for":"email"}},[_vm._v("E-mail*")]),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"v-text-field-custom",attrs:{"error-messages":errors,"tile":"","solo":"","placeholder":"myemail@gmail.com","disabled":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('label',{staticStyle:{"padding-bottom":"5px"},attrs:{"for":"full_name"}},[_vm._v("Full Name*")]),_c('validation-provider',{attrs:{"name":"full_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"v-text-field-custom",attrs:{"error-messages":errors,"tile":"","solo":"","placeholder":"Full Name"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}})]}}],null,true)}),_c('label',{staticStyle:{"padding-bottom":"5px"},attrs:{"for":"orchid"}},[_c('v-tooltip',{attrs:{"top":"","color":"#EE7100","close-delay":2000},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" Orchid ")])]}}],null,true)},[_c('span',[_vm._v(" Open Researcher and Contributor ID For more information see "),_c('a',{staticClass:"tooltip_link",attrs:{"href":"https://orcid.org/","target":"_blank"}},[_vm._v("ORCID")])])])],1),_c('v-text-field',{staticClass:"v-text-field-custom",attrs:{"type":"number","tile":"","solo":"","placeholder":"Orchid"},model:{value:(_vm.orchid),callback:function ($$v) {_vm.orchid=$$v},expression:"orchid"}}),_c('label',{staticStyle:{"padding-bottom":"5px"},attrs:{"for":"workplace"}},[_vm._v("Workplace")]),_c('v-text-field',{staticClass:"v-text-field-custom",attrs:{"tile":"","solo":"","placeholder":"Workplace"},model:{value:(_vm.workplace),callback:function ($$v) {_vm.workplace=$$v},expression:"workplace"}}),_c('v-col',{staticStyle:{"padding":"0px","padding-bottom":"20px"}},[_c('v-btn',{staticStyle:{"width":"100%"},attrs:{"tile":"","color":_vm.submitDisableButtonColor,"x-large":"","disabled":invalid,"type":"submit"}},[_c('span',{staticClass:"login-button-text",staticStyle:{"color":"white"}},[_vm._v(" Update")])])],1)],1)]}}])})],1),_c('Footer')],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }