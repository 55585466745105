<template>
  <div style="padding-left: 1.5%">
    <v-row no-gutters style="padding-top: 20px;">

      <v-col cols="11" class="pl-2 pl-md-10 pl-xl-10 pl-lg-10">
        <span class="my-profile">My Profile</span>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12" class="pl-md-10 pl-xl-10 pl-lg-10 pr-12 pr-md-5 ml-3 ">
        <v-divider style="border-color: #206297; border-width: 2px;"></v-divider>
      </v-col>

    </v-row>
    <v-row>
      <v-col class="pl-md-10 pl-xl-10 pl-lg-10 ml-3" cols="8" sm="8" md="8" lg="3" xl="3" style="padding-bottom: 120px;">
        <div>
          <v-overlay :value="$store.state.overlay.show" :absolute="false">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
          </v-overlay>

          <div class="login-form">

            <validation-observer
                ref="observer"
                v-slot="{ invalid }"
                @change="changeColorByObserver()"


            >
              <v-form
                  ref="form"
                  @submit.prevent="updateProfile($data)"
              >


                <label for="email" style="padding-bottom: 5px;">E-mail*</label>
                <validation-provider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|email"
                >
                  <v-text-field
                      v-model="email"
                      :error-messages="errors"
                      class="v-text-field-custom"
                      tile
                      solo
                      placeholder="myemail@gmail.com"
                      disabled
                  ></v-text-field>
                </validation-provider>

                <label for="full_name" style="padding-bottom: 5px;">Full Name*</label>
                <validation-provider
                    v-slot="{ errors }"
                    name="full_name"
                    rules="required"
                >
                  <v-text-field
                      v-model="full_name"
                      :error-messages="errors"
                      class="v-text-field-custom"
                      tile
                      solo
                      placeholder="Full Name"
                  ></v-text-field>
                </validation-provider>

                <label for="orchid" style="padding-bottom: 5px;">
                  
                  <v-tooltip top="" color="#EE7100" :close-delay="2000">
                      <template  v-slot:activator="{ on, attrs}">
                          <span
                              v-bind="attrs"
                              v-on="on" >
                              Orchid
                          </span>
                      </template>
                      <span >
                          Open Researcher and Contributor ID For more information see <a href="https://orcid.org/" target="_blank" class="tooltip_link">ORCID</a>
                      </span>
                  </v-tooltip>
                </label>
                
                <v-text-field
                    type="number"
                    v-model="orchid"
                    class="v-text-field-custom"
                    tile
                    solo
                    placeholder="Orchid"
                ></v-text-field>

                <label for="workplace" style="padding-bottom: 5px;">Workplace</label>

                <v-text-field
                    v-model="workplace"
                    class="v-text-field-custom"
                    tile
                    solo
                    placeholder="Workplace"
                ></v-text-field>

                <v-col style="padding: 0px; padding-bottom: 20px;">
                  <v-btn
                      tile
                      :color="submitDisableButtonColor"
                      style="width: 100%;"
                      x-large
                      :disabled="invalid"
                      type="submit"
                  >
                    <span class="login-button-text" style="color: white"> Update</span>


                  </v-btn>
                </v-col>


              </v-form>
            </validation-observer>


          </div>
          <Footer></Footer>

        </div>
      </v-col>

    </v-row>
  </div>
</template>
<script>


import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import LoginSubmitButtonMixin from "@/mixins/LoginSubmitButtonMixin";

extend('email', {
  ...email,
  message: 'Email must be valid',
});

// Add the required rule
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: "Profile",
  mixins: [LoginSubmitButtonMixin],
  components: {
    ValidationObserver,
    ValidationProvider
  },

  created() {
    this.loadProfileData();
  },
  data: () => ({
    email: '',
    full_name: '',
    orchid: null,
    workplace: null,
  }),
  methods: {
    loadProfileData() {
      this.$store.dispatch('overlay/show');
      this.$store.dispatch('users/getProfile').then(
          response => {
            console.log(response);
            Object.keys(response).forEach((key) => {
              this.$data[key] = response[key];
            });
            setTimeout(() => {  this.changeColorByObserver(); }, 0);

          },
          error => {
            if (error.response.status !== 403) {
              this.$notify({
                type: 'error',
                group: 'leita',
                duration: 10000,
                title: 'An error has occurred',
                text: 'Refresh the page and try again!'
              });
            }


          }
      )
    },

    updateProfile(payload){
      if (this.$refs.observer.validate()) {
        this.$store.dispatch('users/updateProfile', payload).then(
            response => {
              if (response.result) {

                this.$notify({
                  type: 'success',
                  group: 'leita',
                  duration: 10000,
                  title: 'Success',
                  text: 'Your profile has been updated!'
                });
                this.loadProfileData();
              }
            },
            error => {
              if (error.response.status === 401) {
                this.$refs.observer.setErrors(error.response.data.validation);
              } else {
                this.$notify({
                  type: 'error',
                  group: 'leita',
                  duration: 10000,
                  title: 'An error has occurred',
                  text: 'Refresh the page and try again!'
                });
              }

            }
        );
      }
    }
  }
}
</script>